import * as React from 'react'
import { withNamespaces } from 'react-i18next'
import { PageContext } from '../../../../Context/PageContext'
import {
    Input,
    Label,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from 'reactstrap'
import ReactDragListView from 'react-drag-listview'
import { SubQuestionTypes as QuestionsTypes } from '../../../../constants'
import { Question } from '../..'

const OptionManager = withNamespaces()(({ t, lng, subQuestion, question, ...elseProps }) => {
    const {
        reorder,
        setElementField,
        addQuestionOption,
        deleteQuestionOption,
        setQuestionOptionField,
        setQuestionOptionStateField,
        setQuestionOptionChildField,
        addQuestionOptionChild,
        addSubQuestionOption,
        setSubQustionOptionField,
        setSubQustionOptionStateField,
        deleteSubQuestionOption
    } = React.useContext(PageContext)

    const options = question.options || []

    const optionType =
        question.questionType === 'checkbox' ? 'checkbox' : 'radio'

    const defaultSubQuestionType = QuestionsTypes.find((q) => q.isDefault)

    const handleAddQuestionOption = (newItemTitle) => {
        if (!subQuestion) {
            addQuestionOption(question.id, {
                title: newItemTitle,
                children: [],
                state: {
                    editTitle: true,
                    newSubQuestionType: {
                        ...defaultSubQuestionType
                    }
                }
            })
        } else {
            addSubQuestionOption(
                question.parentQuestionId,
                question.optionIndex,
                question.childIndex,
                {
                    title: newItemTitle,
                    state: {
                        editTitle: true
                    }
                }
            )
        }
    }

    const handleDeleteOption = (optionIndex) => {
        if (!subQuestion) {
            deleteQuestionOption(question.id, optionIndex)
        } else {
            deleteSubQuestionOption(
                question.parentQuestionId,
                question.optionIndex,
                question.childIndex,
                optionIndex
            )
        }
    }

    const renderNewOptionItem = () => {
        if(lng !== 'en') return null

        const newItemTitle = `${t('Item')} ${options.length + 1}`
        return (
            <div className='smartintegrity__questionnaire__designer-option-manager-item'>
                <div className='smartintegrity__questionnaire__designer-option-manager-item-content'>
                    <button
                        onClick={() => handleAddQuestionOption(newItemTitle)}
                        className='text-success'
                    >
                        <i className='ri-add-circle-fill' />
                    </button>
                    <div>
                        <Input disabled type={optionType} />
                        <Label className='disabled'>{newItemTitle}</Label>
                    </div>
                </div>
            </div>
        )
    }

    const saveTitle = (optionIndex, val) => {
        if (!subQuestion) {
            setQuestionOptionField(question.id, optionIndex, 'title', val)
            setQuestionOptionStateField(
                question.id,
                optionIndex,
                'editTitle',
                false
            )
        } else {
            setSubQustionOptionField(
                question.parentQuestionId,
                question.optionIndex,
                question.childIndex,
                optionIndex,
                'title',
                val
            )
            setSubQustionOptionStateField(
                question.parentQuestionId,
                question.optionIndex,
                question.childIndex,
                optionIndex,
                'editTitle',
                false
            )
        }
    }

    const makeTitleEditable = (optionIndex) => {
        if (!subQuestion) {
            setQuestionOptionStateField(
                question.id,
                optionIndex,
                'editTitle',
                true
            )
        } else {
            setSubQustionOptionStateField(
                question.parentQuestionId,
                question.optionIndex,
                question.childIndex,
                optionIndex,
                'editTitle',
                true
            )
        }
    }

    const classNames = subQuestion ? 'sub-question' : 'question'

    return (
        <div className='smartintegrity__questionnaire__designer-option-manager'>
            <ReactDragListView
                nodeSelector={`.smartintegrity__questionnaire__designer-option-manager-item.${classNames}`}
                handleSelector={`.smartintegrity__questionnaire__designer-option-manager-item-drag-btn.${classNames}`}
                onDragEnd={(fromIndex, toIndex) => {
                    if (toIndex < 0) return
                    if (!subQuestion) {
                        setElementField(
                            question.id,
                            'options',
                            reorder(options, fromIndex, toIndex)
                        )
                    } else {
                        setQuestionOptionChildField(
                            question.parentQuestionId,
                            question.optionIndex,
                            question.childIndex,
                            'options',
                            reorder(options, fromIndex, toIndex)
                        )
                    }
                }}
                lineClassName='smartintegrity__questionnaire__designer-option-manager-item-placeholder'
            >
                {options.map((option, optionIndex) => {
                    return (
                        <div
                            className={`smartintegrity__questionnaire__designer-option-manager-item ${classNames}`}
                            key={optionIndex}
                        >
                            <div className='smartintegrity__questionnaire__designer-option-manager-item-content'>
                                <button
                                    className={`smartintegrity__questionnaire__designer-option-manager-item-drag-btn text-secondary ${classNames}`}
                                >
                                    <i className='ri-drag-move-2-fill' />
                                </button>
                                <button
                                    className={`text-danger ${lng !== 'en' && 'd-none'}`}
                                    onClick={() =>
                                        handleDeleteOption(optionIndex)
                                    }
                                >
                                    <i className='ri-close-circle-fill' />
                                </button>
                                <div>
                                    <Input disabled type={optionType} />
                                    {option?.state?.editTitle ? (
                                        <Input
                                            className='w-auto'
                                            type='text'
                                            defaultValue={option.title}
                                            onKeyDown={(e) => {
                                                if (e.keyCode === 13) {
                                                    saveTitle(
                                                        optionIndex,
                                                        e.target.value ||
                                                            option.title
                                                    )
                                                }
                                            }}
                                            onBlur={(e) => {
                                                saveTitle(
                                                    optionIndex,
                                                    e.target.value ||
                                                        option.title
                                                )
                                            }}
                                        />
                                    ) : (
                                        <Label
                                            onClick={() => {
                                                makeTitleEditable(optionIndex)
                                            }}
                                        >
                                            {option.title}
                                        </Label>
                                    )}
                                </div>
                            </div>

                            <div className='smartintegrity__questionnaire__designer-option-manager-item-subquestion-container'>
                                <div className='smartintegrity__questionnaire__designer-option-manager-item-subquestion-items'>
                                    <ReactDragListView
                                        nodeSelector='.smartintegrity__questionnaire__designer-question-item.sub-question'
                                        handleSelector='.smartintegrity__questionnaire__designer-drag-btn.sub-question'
                                        onDragEnd={(fromIndex, toIndex) => {
                                            if (toIndex < 0) return
                                            setQuestionOptionField(
                                                question.id,
                                                optionIndex,
                                                'children',
                                                reorder(
                                                    option.children,
                                                    fromIndex,
                                                    toIndex
                                                )
                                            )
                                        }}
                                        lineClassName='smartintegrity__questionnaire__designer-option-manager-item-placeholder'
                                    >
                                        {(option.children || []).map(
                                            (child, childIndex) => {
                                                return (
                                                    <Question
                                                        key={childIndex}
                                                        subQuestion
                                                        {...child}
                                                        parentQuestionId={
                                                            question.id
                                                        }
                                                        optionIndex={
                                                            optionIndex
                                                        }
                                                        childIndex={childIndex}
                                                        elementIndex={`${String.fromCharCode(childIndex + 97)})`}
                                                    />
                                                )
                                            }
                                        )}
                                    </ReactDragListView>
                                </div>

                                {!subQuestion && lng === 'en' && (
                                    <div className='smartintegrity__questionnaire__designer-option-manager-item-add-subquestion-btn'>
                                        <button
                                            onClick={() => {
                                                addQuestionOptionChild(
                                                    question.id,
                                                    optionIndex
                                                )
                                            }}
                                        >
                                            <i className='ri-add-fill text-success' />
                                            {t('Add sub question with type')}
                                        </button>
                                        <UncontrolledDropdown>
                                            <DropdownToggle caret color='gray'>
                                                {
                                                    option?.state
                                                        ?.newSubQuestionType
                                                        ?.title
                                                }
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                {QuestionsTypes.map(
                                                    (
                                                        subQuestionType,
                                                        typeIndex
                                                    ) => {
                                                        return (
                                                            <DropdownItem
                                                                key={typeIndex}
                                                                onClick={() => {
                                                                    setQuestionOptionStateField(
                                                                        question.id,
                                                                        optionIndex,
                                                                        'newSubQuestionType',
                                                                        subQuestionType
                                                                    )
                                                                }}
                                                            >
                                                                {t(subQuestionType.title)}
                                                            </DropdownItem>
                                                        )
                                                    }
                                                )}
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    </div>
                                )}
                            </div>
                        </div>
                    )
                })}
            </ReactDragListView>

            {renderNewOptionItem()}
        </div>
    )
})

export { OptionManager }
