const groupQuestions = (questionsToGroup, byKey = 'section', itemsTitle = 'items') => {
    const groupNames = {}
    let groupIndex = 0
    const groupResult = []

    questionsToGroup.forEach((question) => {
        const byKeyValue = question[byKey];

        if (!(byKeyValue in groupNames)) {
            groupNames[byKeyValue] = groupIndex++
            groupResult[groupNames[byKeyValue]] = {
                title: byKeyValue,
                icon: question['icon'],
                [itemsTitle]: []
            }
        }

        groupResult[groupNames[byKeyValue]][itemsTitle].push(question)
    })

    return groupResult
}

const renderQuestionAnswerByType = (questionAnswers, question, allValues) => {
    let result = []

    switch (question.type) {
        case 'textarea':
        case 'text':
        case 'date':
            result = [
                {
                    title: questionAnswers
                }
            ]
            break
        case 'checkbox':
        case 'category':
            questionAnswers.forEach((eachCheckedAnswer) => {
                const rawValue = question.options.find(
                    (option) =>
                        (option?.id || '').toString() ===
                        (eachCheckedAnswer || '').toString()
                )
                if (rawValue) {
                    result.push({
                        title: rawValue.title,
                        value: rawValue.id
                    })
                }
            })
            break

        case 'country_list':
        case 'select':
            result = [
                {
                    title: questionAnswers.label,
                    value: questionAnswers.value
                }
            ]
            break

        case 'file_upload':
            {
                const uploadedFiles = Array.from(questionAnswers || [])

                result = uploadedFiles.map((uploadedFile) => {
                    return {
                        title: uploadedFile.id,
                        name: uploadedFile.name,
                        attachmentSize: uploadedFile.attachmentSize || 0
                    }
                })
            }
            break
        case 'radiobox':
            {
                const rawOption = question.options.find(
                    (option) =>
                        (option?.id || '').toString() ===
                        (questionAnswers || '').toString()
                )

                if (rawOption) {
                    result = [
                        {
                            title: rawOption.title,
                            value: rawOption.id
                        }
                    ]

                    const optionChildren = rawOption?.children || []

                    if (optionChildren.length > 0) {
                        const childrenAnswers = []

                        optionChildren.forEach((childQuestion) => {
                            const radioChildAns = allValues[
                                `${question.id}-${childQuestion.id}`
                            ]

                            if (childQuestion.type === 'file_upload') {
                                if(radioChildAns && Array.isArray(radioChildAns) && radioChildAns.length > 0){
                                    childrenAnswers.push({
                                        id: childQuestion.id,
                                        title: radioChildAns[0].id,
                                        type: 'file_upload',
                                        name: radioChildAns[0].name,
                                        attachmentSize: radioChildAns[0].attachmentSize || radioChildAns[0].name
                                    })
                                }
                            } else if (
                                childQuestion.type === 'radiobox'
                            ) {
                                childrenAnswers.push({
                                    id: childQuestion.id,
                                    title: childQuestion.title,
                                    type: 'radiobox',
                                    value: radioChildAns
                                })
                            } else {
                                childrenAnswers.push({
                                    id: childQuestion.id,
                                    title: radioChildAns,
                                    type: childQuestion.type
                                })
                            }
                        })

                        result[0].children = childrenAnswers
                    }
                }
            }

            break
    }

    return result
}

const renderAnswer = (values, question) => {
    const itemResult = {
        question: question.id,
        answer: []
    }
    const questionAnswers = values[question.id]

    if (questionAnswers) {
        itemResult.answer = renderQuestionAnswerByType(
            questionAnswers,
            question,
            values
        )
    }

    return itemResult
}

const isBasedOnQuestionAnswered = (basedOnCondition, questions, latestValues) => {
    let relatedQuestion = null

    for(let i = 0; i < questions.length; i++){
        const sectionQuestions = questions[i].questions || []

        relatedQuestion = sectionQuestions.find((question) => {
            return question.front_id === basedOnCondition.question
        })

        if(relatedQuestion){
            break
        }
    }

    if(relatedQuestion){
        const relatedQuestionAnswer = latestValues[relatedQuestion.id]

        if(relatedQuestionAnswer !== undefined){
            const relatedQuestionAnswerOption = (relatedQuestion.options || []).find((option) => {
                return parseInt(option.id) === parseInt(relatedQuestionAnswer)
            })

            if(relatedQuestionAnswerOption && relatedQuestionAnswerOption.front_id === basedOnCondition.option){
                return true
            }
        }
    }

    return false;
}

const makeQuestionsAnswersReadyToSend = ({
    multiTab,
    questions,
    values,
    touchedFields
}) => {
    const result = []

    if (multiTab) {
        if (touchedFields) {
            const findQuestionById = (id) => {
                for (let index = 0; index < questions.length; index++) {
                    const section = questions[index]

                    for (let j = 0; j < section.questions.length; j++) {
                        const question = section.questions[j]

                        if (question.id.toString() === id) {
                            return question
                        }
                    }
                }
                return null
            }

            for (const touchedFieldKey in touchedFields) {
                const splitedFieldKey = touchedFieldKey.split('-')
                if (splitedFieldKey.length === 1) {
                    const question = findQuestionById(splitedFieldKey[0])
                    result.push(renderAnswer(values, question))
                }
            }
        } else {
            for (let index = 0; index < questions.length; index++) {
                const section = questions[index] || {}

                section.questions.forEach((question) => {
                    const addQuestionToList = (question.based_on && question.based_on.question && !isBasedOnQuestionAnswered(question.based_on, questions, values)) ? false : true
                    
                    if(addQuestionToList){
                        result.push(renderAnswer(values, question))
                    }
                })
            }
        }
    }
    return result
}

const makeQuestionsReadyForQuestionnaire = (questions, multiTab = false) => {    
    const groupedBySectionsList = groupQuestions(questions, 'section');

    const result = groupedBySectionsList.map((section, index) => {
        const groupedBySubSection = groupQuestions(section.items, 'sub_section', 'questions');

        return {
            id: index,
            title: section.title,
            icon: section.icon,
            questions: section.items,
            panels: groupedBySubSection
        }
    });

    return result
}

const addChildAnswerToResult = (result, keyToAdd, ans) => {
    switch(ans.type){
        case 'textarea':
        case 'text':
        case 'date':
            result[keyToAdd] = ans.title
            break

        case 'file_upload':
            result[keyToAdd] = [
                {
                    id: ans.title,
                    title: ans.title,
                    name: ans.name,
                    attachmentSize: ans.file_size || 0
                }
            ]
            break
    }
}

const makePreviousAnswersReadyForQuestionnaire = (questions, answers, answerKey = 'answers') => {
    const result = {}

    questions.map((rawQuestion) => {
        const questionPreFilledAnswers = answers.find(
            (ans) => ans.id === rawQuestion.id
        )[answerKey]

        if (questionPreFilledAnswers) {
            const keyForQuestionAnswer = `${rawQuestion.id}`

            switch (rawQuestion.type) {
                case 'textarea':
                case 'text':
                case 'date':
                    result[keyForQuestionAnswer] =
                        questionPreFilledAnswers[0]?.title ||
                        rawQuestion?.system_data ||
                        ''
                    break

                case 'checkbox':
                case 'category':
                    result[keyForQuestionAnswer] = questionPreFilledAnswers.map(
                        (checkValue) => {
                            return checkValue.value
                        }
                    )
                    break

                case 'country_list':
                case 'select':{
                    result[keyForQuestionAnswer] = questionPreFilledAnswers.map(
                        (eachValue) => {
                            return {
                                value: eachValue.value,
                                label: eachValue.title
                            }
                        }
                    )

                    const selectedValue = result[keyForQuestionAnswer][0]?.value

                    if(selectedValue){
                        const rawOption = rawQuestion.options.find((op) => parseInt(op.id) === parseInt(selectedValue))

                        if(rawOption && Array.isArray(rawOption.children)){
                            rawOption.children.forEach((child) => {
                                const childAns = (questionPreFilledAnswers[0]?.children || []).find((chAns) => parseInt(chAns.id) === parseInt(child.id))
    
                                if(childAns){
                                    addChildAnswerToResult(result, `${keyForQuestionAnswer}-${childAns.id}`, childAns)
                                }
                            })
                        }
                    }

                    break
                }

                case 'file_upload':
                    result[keyForQuestionAnswer] = (questionPreFilledAnswers || []).map((uploadedFile) => {
                        return {
                            id: uploadedFile.title,
                            title: uploadedFile.title,
                            name: uploadedFile.name,
                            attachmentSize: uploadedFile.file_size || 0
                        }
                    })
                    break

                case 'radiobox':{
                    if(questionPreFilledAnswers.length > 0){
                        result[keyForQuestionAnswer] =
                            questionPreFilledAnswers[0].value

                        const rawOption = rawQuestion.options.find((op) => parseInt(op.id) === parseInt(result[keyForQuestionAnswer]))

                        if(rawOption && Array.isArray(rawOption.children)){
                            rawOption.children.forEach((child) => {
                                const childAns = (questionPreFilledAnswers[0].children || []).find((chAns) => parseInt(chAns.id) === parseInt(child.id))

                                if(childAns){
                                    addChildAnswerToResult(result, `${keyForQuestionAnswer}-${childAns.id}`, childAns)
                                }
                            })
                        }
                    }
                    break
                }
                default:
                    return null
            }
        }
    })

    return result
}

const guessQuestionAnsweredByAnswerType = (answer) => {
    if (Array.isArray(answer)) {
        return answer.length > 0
    } 
    
    if (typeof answer === 'object') {
        return Object.keys(answer).length > 0
    } 

    return answer !== '' && answer !== null && answer !== undefined
}

const makeQuestionsReadyForAnswerSheet = (questions, multiTab = false) => {
    return makeQuestionsReadyForQuestionnaire(questions, multiTab)
}

const makeAnswersReadyForAnswerSheet = (questions, gotAnswers, multiTab = false) => {
    const latestValues = makePreviousAnswersReadyForQuestionnaire(questions, gotAnswers);

    return makeQuestionsAnswersReadyToSend({
        multiTab: true,
        questions: makeQuestionsReadyForQuestionnaire(questions),
        values: latestValues
    })
}

export {
    makeQuestionsAnswersReadyToSend,
    makeQuestionsReadyForQuestionnaire,
    makePreviousAnswersReadyForQuestionnaire,
    renderQuestionAnswerByType,
    guessQuestionAnsweredByAnswerType,
    isBasedOnQuestionAnswered,
    makeQuestionsReadyForAnswerSheet,
    makeAnswersReadyForAnswerSheet
}
