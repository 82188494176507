import * as React from 'react'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { withNamespaces } from 'react-i18next'
import { Row, Col, Button, Progress } from 'reactstrap'
import { ReactStrapQuestion } from '../components/Question'
import Accordion from 'react-bootstrap/Accordion'
import {
    makeQuestionsAnswersReadyToSend,
    guessQuestionAnsweredByAnswerType,
    isBasedOnQuestionAnswered
} from '../../helpers/questionnaire'
import {
    QuestionnairePagesImages
} from '../constants'

export const MultiTabQuestionnaire = withNamespaces()(({
    questions,
    defaultValues = {},
    onSubmit,
    onQuestionnaireCompleteStatusChanged,
    settings,
    className,
    onChange,
    t,
    ...elseProps
}) => {
    const methods = useForm({
        defaultValues: defaultValues
    })
    const latestValues = useWatch({
        control: methods.control,
        defaultValues: defaultValues
    })
    const [activeTabIndex, setActiveTabIndex] = React.useState(0)
    const [tabsAnsweredQuestions, setTabsAnsweredQuestions] =
        React.useState({})

    const isInitChangeEvent = React.useRef(true)

    const handleSubmit = (data) => {
        onSubmit &&
            onSubmit(
                makeQuestionsAnswersReadyToSend({
                    multiTab: true,
                    questions: questions,
                    values: data
                })
            )
    }

    const calculateQuestionnaireStatus = () => {
        const checkQuestionIsForTab = (tabId, questionId) => {
            const parentQuestionId = parseInt(
                questionId.toString().split('-')[0]
            )

            return !!questions.find((tab) => {
                return tab.id === tabId
            }).questions.find((q) => {
                return parseInt(q.id) === parentQuestionId
            })
        }

        let completed = true

        const {
            control: { _fields }
        } = methods

        questions.forEach((_tabQuestions, tabIndex) => {
            if(questions[tabIndex]){
                const tabId = questions[tabIndex].id
        
                let tabTotalRequiredQuestions = 0
                let tabAnsweredRequiredQuestions = 0


                let tabTotalOptionalQuestions = 0
                let tabAnsweredOptionalQuestions = 0
    
                for (const id in _fields) {
                    const field = _fields[id]._f
                    if (field.mount) { // field?.required
                        const questionAnswered = guessQuestionAnsweredByAnswerType(
                            latestValues[id]
                        )
        
                        if (checkQuestionIsForTab(tabId, id)) {
                            if(field.required){
                                tabTotalRequiredQuestions++
                                if (questionAnswered) {
                                    tabAnsweredRequiredQuestions++
                                }
                                else{
                                    completed = false
                                }
                            }
                            else{
                                tabTotalOptionalQuestions++
                                if (questionAnswered) {
                                    tabAnsweredOptionalQuestions++
                                }
                            }
                        }
        
                        if (field.required && !questionAnswered) {
                            completed = false
                        }
                    }
                }

                setTabsAnsweredQuestions((currentState) => {
                    const newState = [...currentState]
                    newState[tabId] = {
                        required: tabTotalRequiredQuestions,
                        requiredAnswered: tabAnsweredRequiredQuestions,

                        optional: tabTotalOptionalQuestions,
                        optionalAnswered: tabAnsweredOptionalQuestions
                    }
                    return newState
                })
            }
        })

        return {
            questionnaireCompleteStatus: completed
        }
    }

    const renderQuestions = (panels, pagePrefix) => {
        let panelIndex = 0

        return (panels || []).filter((panel) => {
            const firstDisplableQuestionIndex = panel.questions.findIndex((question) => {
                return (question.based_on && question.based_on.question && !isBasedOnQuestionAnswered(question.based_on, questions, latestValues)) ? false : true
            })

            return firstDisplableQuestionIndex > -1
        }).map((panel) => {
            if(!panel.title){
                return (
                    <div className='panel no-title'>
                        <div className='panel-items'>
                            {(panel.questions || []).map((question, index) => {
                                const displayQuestion = (question.based_on && question.based_on.question && !isBasedOnQuestionAnswered(question.based_on, questions, latestValues)) ? false : true

                                const questionTitlePrefix = tabSettings?.showNumber ? `${pagePrefix}.${panelIndex + 1}` : null

                                displayQuestion && panelIndex++

                                return (
                                    <ReactStrapQuestion
                                        key={index}
                                        question={question}
                                        settings={settings}
                                        questionTitlePrefix={questionTitlePrefix}
                                        displayQuestion={displayQuestion}
                                    />
                                )
                            })}
                        </div>
                    </div>
                )
            }

            const panelTitlePrefix = tabSettings?.showNumber ? `${pagePrefix}.${panelIndex + 1}` : ''
            panelIndex++

            let questionIndex = 0

            return (
                <div className='panel'>
                    <div className='panel-title'>
                        <h4>
                            {panelTitlePrefix && (
                                <span className="prefix">
                                    {panelTitlePrefix}
                                </span>
                            )}
                            {t(panel.title)}
                        </h4>
                    </div>
                    <div className='panel-items'>
                        {(panel.questions || []).map((question, index) => {
                            const displayQuestion = (question.based_on && question.based_on.question && !isBasedOnQuestionAnswered(question.based_on, questions, latestValues)) ? false : true

                            const questionTitlePrefix = panelTitlePrefix ? `${panelTitlePrefix}.${questionIndex + 1}` : null

                            displayQuestion && questionIndex++

                            return (
                                <ReactStrapQuestion
                                    key={index}
                                    question={question}
                                    settings={settings}
                                    displayQuestion={displayQuestion}
                                    questionTitlePrefix={questionTitlePrefix}
                                />
                            )
                        })}
                    </div>
                </div>
            )

        })
    }

    React.useEffect(() => {
        const {
            questionnaireCompleteStatus
        } = calculateQuestionnaireStatus()

        const questionnaireState = makeQuestionsAnswersReadyToSend({
            multiTab: true,
            questions: questions,
            values: latestValues
        })

        if(isInitChangeEvent.current){
            isInitChangeEvent.current = false

            if(questionnaireCompleteStatus){
                onQuestionnaireCompleteStatusChanged && onQuestionnaireCompleteStatusChanged(
                    questionnaireState,
                    true
                )

                if(elseProps.autoSaveState.enabled){
                    elseProps.autoSaveUpdateOptions((currentOptions) => {
                        return {
                            ...currentOptions,
                            stepInterval: 0
                        }
                    })
                }
            }

            return
        }

        onQuestionnaireCompleteStatusChanged && onQuestionnaireCompleteStatusChanged(
            questionnaireState,
            questionnaireCompleteStatus
        )
        
        if(elseProps.autoSaveState.enabled){
            elseProps.autoSaveUpdatePayload({
                payload: questionnaireState,
                isFinal: questionnaireCompleteStatus
            })
        }

    }, [ latestValues])

    const { tabSettings, buttonsSettings } = settings || {}

    return (
        <FormProvider {...methods}>
            <div className='smartintegrity__questionnaire'>
                <form
                    className={className}
                    onSubmit={methods.handleSubmit(handleSubmit)}
                >
                    <Accordion defaultActiveKey={activeTabIndex}>
                        {questions.filter((tab) => {
                            const firstDisplableQuestionIndex = tab.questions.findIndex((question) => {
                                return (question.based_on && question.based_on.question && !isBasedOnQuestionAnswered(question.based_on, questions, latestValues)) ? false : true
                            })

                            return firstDisplableQuestionIndex > -1
                        }).map((tab, tabIndex) => {
                            const requiredAnsweredQuestionsCount = tabsAnsweredQuestions[tab.id]?.requiredAnswered || 0
                            const requiredQuestions =  tabsAnsweredQuestions[tab.id]?.required || 0

                            const optionalAnsweredQuestionsCount = tabsAnsweredQuestions[tab.id]?.optionalAnswered || 0
                            const optionalQuestions =  tabsAnsweredQuestions[tab.id]?.optional || 0

                            let tabRequiredPercent = 0
                            let tabOptionalPercent = 0

                            if(requiredQuestions){
                                tabRequiredPercent = (requiredAnsweredQuestionsCount / requiredQuestions * 100).toFixed(0)
                            }

                            if(optionalQuestions){
                                tabOptionalPercent = (optionalAnsweredQuestionsCount / optionalQuestions * 100).toFixed(0)
                            }

                            return (
                                <Accordion.Item
                                    key={tab.id}
                                    onClick={() => setActiveTabIndex(0)}
                                    className={
                                        activeTabIndex === tabIndex ? 'active' : ''
                                    }
                                    eventKey={tabIndex}
                                >
                                    <Accordion.Header>
                                        <div className='section-header'>
                                            <div className='d-flex align-items-center'>
                                                {QuestionnairePagesImages[tab.icon]?.src ? (
                                                    <img src={QuestionnairePagesImages[tab.icon]?.src} alt="" />
                                                ) : (
                                                    <i 
                                                        className={`${tab.icon} page-icon`}
                                                    />
                                                )}

                                                <span>
                                                    {tabSettings?.showNumber && (
                                                        <span className="prefix">
                                                            {`${tabIndex + 1}.`}
                                                        </span>
                                                    )}
                                                    {t(tab.title)}
                                                </span>
                                            </div>
                                            {tabSettings?.showProgressBar && (
                                                <div className="completed-percent">
                                                    <div className={`required-questions ${!requiredQuestions ? 'd-none' : ''}`}>
                                                        <div className='titles'>
                                                            <span className='group-title'>
                                                                {`${t('Mandatory')}: ${requiredAnsweredQuestionsCount}/${requiredQuestions}`}
                                                            </span>
                                                            <span className='percent-title'>
                                                                {tabRequiredPercent >= 100 ? t('Completed') : `${tabRequiredPercent}%`}
                                                            </span>
                                                        </div>
                                                        <Progress 
                                                            className={tabRequiredPercent >= 100 ? 'completed' : 'incompleted'} 
                                                            color="success" 
                                                            value={tabRequiredPercent} 
                                                        />
                                                    </div>

                                                    <div className={`optional-questions ${!optionalQuestions ? 'd-none' : ''}`}>
                                                        <div className='titles'>
                                                            <span className='group-title'>
                                                                {`${t('Optional')}: ${optionalAnsweredQuestionsCount}/${optionalQuestions}`}
                                                            </span>
                                                            <span className='percent-title'>
                                                                {tabOptionalPercent >= 100 ? t('Completed') : `${tabOptionalPercent}%`}
                                                            </span>
                                                        </div>
                                                        <Progress 
                                                            className={tabOptionalPercent >= 100 ? 'completed' : 'incompleted'} 
                                                            color="success" 
                                                            value={tabOptionalPercent} 
                                                        />
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </Accordion.Header>

                                    <Accordion.Body>
                                        { renderQuestions(tab.panels, tabIndex + 1) }
                                    </Accordion.Body>
                                </Accordion.Item>
                            )
                        })}
                    </Accordion>

                    <Row>
                        <Col
                            sm='12'
                            className='d-flex justify-content-between mt-2 mb-2'
                        >
                            {!!buttonsSettings.back?.display && (
                                <Button
                                    onClick={() => {
                                        if (buttonsSettings.back?.onClick) {
                                            buttonsSettings.back.onClick()
                                        }
                                    }}
                                    disabled={!buttonsSettings.back?.enable}
                                    color='btn btn-secondary'
                                    className={`${buttonsSettings?.back?.customClass}`}
                                >
                                    {buttonsSettings?.back?.title || t('Back')}
                                </Button>
                            )}

                            {!!buttonsSettings.submit?.display && (
                                <Button
                                    disabled={!buttonsSettings.submit?.enable}
                                    type='submit'
                                    color='primary'
                                    className={`${buttonsSettings?.submit?.customClass}`}
                                >
                                    {buttonsSettings?.submit?.title || t('Submit')}
                                </Button>
                            )}
                        </Col>
                    </Row>
                </form>
            </div>
        </FormProvider>
    )
})