import * as React from 'react'
import { withNamespaces } from 'react-i18next'
import {
    Button,
    Spinner,
    InputGroup,
    Input
} from 'reactstrap'

const SaveQuestionnaire = withNamespaces()(({ t, title, savingInprocess, onSaveButtonClicked }) => {
    const [ questionnaireTitle, setQuestionnaireTitle ] = React.useState(title)

    return (
        <InputGroup
            className='saving-component'
        >
            <Input 
                value={t(questionnaireTitle)}
                placeholder={t('Questionnaire Title (Required)')}
                onChange={(e) => {
                    setQuestionnaireTitle(e.target.value)
                }}
            />
            <Button
                disabled={savingInprocess || !questionnaireTitle?.length}
                color='primary'
                onClick={() => {
                    onSaveButtonClicked({
                        title: questionnaireTitle
                    })
                }}
            >
                {savingInprocess ? (
                    <React.Fragment>
                        <Spinner
                            className='me-1'
                            size='sm'
                        />
                        {t('Saving')}...
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <i className='ri-save-line me-1' />
                        {t('Save')}
                    </React.Fragment>
                )}
            </Button>
        </InputGroup>
    )
})

export default SaveQuestionnaire