import {
    FileTypesAndImages
} from '../constants'

const getFileType = (fileName) => {
    if (!fileName) return 'Unknown'

    const extension = fileName.split('.').pop().toLowerCase()

    for (const [type, options] of Object.entries(FileTypesAndImages)) {
        if (options.types.includes(extension)) {
            return type
        }
    }

    return 'unknown'
}

const getFileTypeImage = (fileName) => {
    return FileTypesAndImages[getFileType(fileName)]?.image
}

export {
    getFileType,
    getFileTypeImage
}