import * as React from 'react'
import { withNamespaces } from 'react-i18next'
import {
    Modal,
    ModalHeader,
    ModalBody,
    Input,
    ListGroup,
    ListGroupItem
} from 'reactstrap'

const IconSelector = ({
    t,
    hideModal,
    showModal,
    defaultValue,
    onIconSelect
}) => {
    const [icons, setIcons] = React.useState([])
    const [searchText, setSearchText] = React.useState('')
    const [debouncedSearch, setDebouncedSearch] = React.useState('')

    React.useEffect(() => {
        fetch('/images/questionnaire/icons/RemixIcon.json')
            .then((response) => response.json())
            .then((data) => setIcons(data))
    }, [])

    React.useEffect(() => {
        if(showModal){
            setSearchText('')
        }
    }, [showModal])

    React.useEffect(() => {
        const timeoute = setTimeout(() => {
            setDebouncedSearch(searchText)
        }, 300)

        return () => clearTimeout(timeoute)
    }, [searchText])

    const filteredIcons = React.useMemo(() => {
        return icons.filter((icon) => {
            return icon.title.toLowerCase().includes(debouncedSearch.toLowerCase()) || 
                icon.keywords.some((keyword) => keyword.toLowerCase().includes(
                    debouncedSearch.toLowerCase()
                ))
        })
    }, [debouncedSearch, icons])

    const defaultSelectedItem = defaultValue ? icons.find((icon) => icon.name === defaultValue) : null

    return (
        <Modal
            className='smartintegrity__questionnaire__designer-container-page-image-selector-modal'
            isOpen={showModal}
            toggle={hideModal}
            size='md'
        >
            <ModalHeader
                toggle={hideModal}
            >
                {t('Select image')}
            </ModalHeader>

            <ModalBody>
                <Input
                    type='text'
                    placeholder={`${t('Search images by name or keyword')}...`} 
                    onChange={(e) => setSearchText(e.target.value)}
                    className='mb-2'
                />

                <ListGroup
                    flush
                    style={{
                        maxHeight: '300px',
                        overflow: 'auto'
                    }}
                >
                    {defaultSelectedItem && (
                        <ListGroupItem
                            color='info'
                            className='d-flex align-items-center justify-content-between'
                        >
                            <div
                                className='d-flex align-items-center'
                            >
                                <i 
                                    className={`${defaultSelectedItem.name} me-2 `} 
                                />
                                <span>  
                                    {defaultSelectedItem.title}
                                </span>
                            </div>
                            <strong>{t('Selected')}</strong>
                        </ListGroupItem>
                    )}

                    {filteredIcons.map(
                        (pageImage, pageImageIndex) => {
                            return (
                                <ListGroupItem
                                    action
                                    onClick={() => onIconSelect(pageImage.name)}
                                    color={
                                        pageImage === defaultValue ? 'info' : null
                                    }
                                    key={pageImageIndex}
                                    className='d-flex align-items-center'
                                >
                                    <i 
                                        className={`${pageImage.name} me-2 `} 
                                    />
                                    <span>  
                                        {pageImage.title}
                                    </span>
                                </ListGroupItem>
                            )
                        }
                    )}
                    {!filteredIcons.length && (
                        <ListGroupItem
                            color='warning'
                            className='d-flex align-items-center justify-content-between mt-2'
                        >
                            <p className='mb-0'>{t('No images found. Try a different search term.')}</p>
                        </ListGroupItem>
                    )}
                </ListGroup>
            </ModalBody>
        </Modal>
    )
}

export default withNamespaces()(
    IconSelector
);