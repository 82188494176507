import './styles.module.css'
import { 
    SimpleQuestionnaire, 
    MultiTabQuestionnaire, 
    useAutoSave, 
    AnswerSheet 
} from './Questionnaire'

import { QuestionnaireDesigner } from './QuestionnaireDesigner'

import {
    makeQuestionsReadyForQuestionnaire,
    makePreviousAnswersReadyForQuestionnaire,
    makeQuestionsAnswersReadyToSend,
    makeQuestionsReadyForAnswerSheet,
    makeAnswersReadyForAnswerSheet
} from './helpers/questionnaire'

const helpers = {
    makeQuestionsReadyForQuestionnaire,
    makePreviousAnswersReadyForQuestionnaire,
    makeQuestionsAnswersReadyToSend,
    makeQuestionsReadyForAnswerSheet,
    makeAnswersReadyForAnswerSheet
}

export { 
    SimpleQuestionnaire, 
    MultiTabQuestionnaire, 
    helpers, 
    AnswerSheet, 
    useAutoSave 
}

export { QuestionnaireDesigner }
