import {
    QuestionnairePagesImages,
    FileTypesAndImages
} from '../../constants'

import {
    getFileTypeImage
} from '../../helpers/general'

const QuestionnaireEmptyImageSrc = '/images/questionnaire/pages/no-pictures.png'
const UnknownFileTypePlaceholder = '/images/questionnaire/no-pictures.png'

export {
    QuestionnairePagesImages,
    QuestionnaireEmptyImageSrc,
    UnknownFileTypePlaceholder,
    FileTypesAndImages,
    getFileTypeImage
}