import * as React from 'react'
import Accordion from 'react-bootstrap/Accordion'
import { withNamespaces } from 'react-i18next'
import { 
    Row, 
    Col, 
    Button,
    Label
} from 'reactstrap'
import { 
    QuestionnairePagesImages,
    getFileTypeImage
} from '../constants'
import axios from 'axios'
import { toast } from 'react-toastify'

const checkBasedOnConditions = (question, questions, answers) => {
    if(!question.based_on?.question) return true

    let relatedQuestion = null

    for(let i = 0; i < questions.length; i++){
        const sectionQuestions = questions[i].questions || []

        relatedQuestion = sectionQuestions.find((questionToSearch) => {
            return questionToSearch.front_id === question.based_on.question
        })

        if(relatedQuestion){
            break
        }
    }

    if(relatedQuestion){
        const relatedQuestionAnswer = answers.find((ans) => {
            return ans.question === relatedQuestion.id
        })?.answer

        if(Array.isArray(relatedQuestionAnswer)){
            const selectedOption = (relatedQuestion.options || []).find((option) => {
                return option.id === relatedQuestionAnswer[0].value
            })

            return selectedOption?.front_id === question.based_on.option
        }
    }

    return false
}

const FileDownloader = ({
    t,
    fileDownloaderOptions,
    questionId,
    file: {
        name: fileName,
        title: attachmentId,
        attachmentSize = 0
    }
}) => {
    const [downloadingFile, setDownloadingFile] = React.useState(null)

    const handleDownloadFile = async () => {
        const file = {
            id: attachmentId,
            name: fileName,
            percent: 0,
            status: "downloading",
            cancelToken: axios.CancelToken.source()
        }

        setDownloadingFile(file)

        let loadedBytes = 0

        const { downloadRequestOptions } = fileDownloaderOptions || {}

        const requestUrl =
                typeof downloadRequestOptions.requestUrl === 'function'
                    ? downloadRequestOptions.requestUrl({
                          questionId: questionId,
                          attachmentId: attachmentId
                      })
                    : downloadRequestOptions.requestUrl

        try {
            const response = await axios.post(
                requestUrl,
                // {...(downloadRequestOptions?.payload || {})},
                null,
                {
                    responseType: "blob",
                    headers: {
                        ...(downloadRequestOptions?.headers || {})
                    },
                    onDownloadProgress: (progressEvent) => {
                        loadedBytes = progressEvent.loaded
                        const percentCompleted = Math.round(
                            (loadedBytes * 100) / attachmentSize
                        )

                        if(percentCompleted >= 100){
                            setDownloadingFile(null)
                        }
                        else{
                            setDownloadingFile((prevState) =>
                                ({ ...prevState, percent: percentCompleted })
                            )
                        }
                    },
                    cancelToken: file.cancelToken.token
                }
            )
        
            const url = window.URL.createObjectURL(
                new Blob([response.data], { type: "*" })
            )
            const link = document.createElement("a")
        
            link.href = url
            link.setAttribute("download", `${fileName.split(".")[0]}.zip`)
        
            document.body.appendChild(link)
        
            link.click()
            link.remove()
            setDownloadingFile(null)
        } catch (error) {
            if (axios.isCancel(error)) {
                toast(t("Download cancelled."), {
                    type: "success",
                })
            } else {
                toast(t("An error occurred while downloading the file."), {
                    type: "error",
                })
            }
            setDownloadingFile(null)
        }
    } 

    const handleCancelDownload = () => {
        const cancelToken = downloadingFile?.cancelToken
        if (cancelToken) {
            cancelToken.cancel("Download cancelled by user")
            setDownloadingFile(null)
        }
    }

    return (
        <div className="file-preview">
            <div className="file-details">
                <span onClick={() => {
                    handleDownloadFile()
                }} className="file-name">
                    { fileName }
                </span>
                <img alt="" src={getFileTypeImage(fileName)}/>
            </div>

            {downloadingFile && (
                <div className="download-status-container">
                    <span>
                        {t("Downloading")}...
                    </span>

                    <div className='percent-container'>
                        <span className="download-percent">
                            {Math.ceil(
                                downloadingFile.percent === Infinity
                                    ? 100
                                    : downloadingFile.percent
                                )}
                            %
                        </span>
                        <button
                            type="button"
                            className="btn noborder p-0 ms-1"
                            onClick={() => handleCancelDownload()}
                        >
                            <i className="ri-close-line font-size-18"></i>
                        </button>
                    </div>
                </div>
            )}
        </div>
    )
}

const SubQuestion = withNamespaces()(({
    t,
    question,
    fileDownloaderOptions,
    prefix,
    answer
}) => {
    const {
        title,
        type
    } = question

    switch(type){
        case 'textarea':
        case 'text':
        case 'country_list':
        case 'select':
        case 'date':
            return (
                <div className="question">
                    <Label className="form-label question-title">
                        {prefix && (
                            <span className="prefix">
                                {prefix}
                            </span>
                        )}
                        {t(title)}
                    </Label>
                    <p>
                        {answer?.title}
                    </p>
                </div>
            )
        case 'file_upload':
            return (
                <div className="question">
                    <Label className="form-label question-title">
                        {prefix && (
                            <span className="prefix">
                                {prefix}
                            </span>
                        )}
                        {t(title)}
                    </Label>

                    <div className="files-container">
                        <FileDownloader 
                            t={t}
                            file={answer}
                            fileDownloaderOptions={fileDownloaderOptions}
                            questionId={question.id}
                        />
                    </div>
                </div>
            )
        default:
            break
    }
})

const Question = withNamespaces()(({
    t,
    question, 
    index,
    subSectionPrefix,
    fileDownloaderOptions,
    answer: {
        answer = []
    } = {}
}) => {
    const {
        title,
        type
    } = question

    switch(type){
        case 'textarea':
        case 'text':
        case 'country_list':
        case 'select':
            return (
                <div className="question">
                    <Label className="form-label question-title">
                        <span className="prefix">
                            { `${subSectionPrefix ? subSectionPrefix + '.': ''}${index}` }
                        </span>
                        {t(title)}
                    </Label>
                    <p className='answer'>
                        {answer[0]?.title}
                    </p>
                </div>
            )
        
        case 'date':
            return (
                <div className="question">
                    <Label className="form-label question-title">
                        <span className="prefix">
                            { `${subSectionPrefix ? subSectionPrefix + '.': ''}${index}` }
                        </span>
                        {t(title)}
                    </Label>
                    <p className='answer'>
                        {answer[0]?.title}
                    </p>
                </div>
            )
        
        case 'checkbox':
        case 'category':
            return (
                <div className="question">
                    <Label className="form-label question-title">
                        <span className="prefix">
                            { `${subSectionPrefix ? subSectionPrefix + '.': ''}${index}` }
                        </span>
                        {t(title)}
                    </Label>
                    <Row>
                        <Col sm="12" md="6">
                            <p>
                                {t('Checked 1')}
                            </p>
                        </Col>
                        <Col sm="12" md="6">
                            <p>
                                {t('Checked 2')}
                            </p>
                        </Col>
                    </Row>
                </div>
            )

        case 'radiobox':{
            const selectedOption = question.options.find((op) => op.id === answer[0]?.value)

            const children = selectedOption?.children || []

            return (
                <div className="question">
                    <Label className="form-label question-title">
                        <span className="prefix">
                            { `${subSectionPrefix ? subSectionPrefix + '.': ''}${index}` }
                        </span>
                        {t(title)}
                    </Label>
                    <Row>
                        <Col sm="12">
                            <p className='answer'>
                                {t(answer[0]?.title)}
                            </p>
                            {children.length > 0 && (
                                <div className="sub-question-container">
                                    {children.map((childQuestion, index) => {
                                        const childAns = (answer[0]?.children || []).find((ans) => {
                                            return ans.id === childQuestion.id
                                        })

                                        return (
                                            <SubQuestion 
                                                key={index}
                                                question={childQuestion}
                                                answer={childAns}
                                                fileDownloaderOptions={fileDownloaderOptions}
                                                prefix={`${String.fromCharCode(97 + index)})`}
                                            />
                                        )
                                    })}
                                </div>
                            )}
                        </Col>
                    </Row>
                </div>
            )
        }
        
        case 'file_upload':
            const uploadedFiles = answer || []
            return (
                <div className="question">
                    <Label className="form-label question-title">
                        <span className="prefix">
                            { `${subSectionPrefix ? subSectionPrefix + '.': ''}${index}` }
                        </span>
                        {t(title)}
                    </Label>

                    {uploadedFiles.length > 0 && (
                        <div className="files-container">
                            {uploadedFiles.map((file, index) => {
                                return (
                                    <FileDownloader 
                                        key={index}
                                        fileDownloaderOptions={fileDownloaderOptions}
                                        t={t}
                                        file={file}
                                        questionId={question.id}
                                    />
                                )
                            })}
                        </div>
                    )}
                </div>
            )
        default:
            break
    }

    return question.title
})

export const AnswerSheet = withNamespaces()(({
    t,
    settings,
    questions,
    answers,
    onBackButtonClicked,
    onConfirmed
}) => {
    const findAnswer = ({
        id
    }) => {
        return (answers || []).find((answer) => {
            return answer.question === id
        })
    }
    
    const renderQuestions = (panels, pagePrefix) => {
        let panelIndex = 0

        return (panels || []).filter((panel) => {
            const firstDisplableQuestionIndex = (panel.questions || []).findIndex((question) => {
                const displayQuestion = checkBasedOnConditions(
                    question, questions, answers
                )

                return displayQuestion  
            })

            return firstDisplableQuestionIndex > -1
        }).map((panel) => {
            if(!panel.title){
                return (
                    <div className='sub-section no-title'>
                        <div className='sub-section-body'>
                            {(panel.questions || []).map((question, index) => {
                                const displayQuestion = checkBasedOnConditions(
                                    question, questions, answers
                                )

                                if(!displayQuestion) return null

                                panelIndex++

                                return (
                                    <Question
                                        index={panelIndex}
                                        key={index}
                                        question={question}
                                        answer={findAnswer(question)}
                                        subSectionPrefix={pagePrefix}
                                        fileDownloaderOptions={settings?.fileDownloaderOptions || {}}
                                    />
                                )
                            })}
                        </div>
                    </div>
                )
            }

            const panelPrefix = `${pagePrefix}.${panelIndex + 1}`

            panelIndex++

            let questionIndex = 0

            return (
                <div className='sub-section'>
                    <Label className="sub-section-title">
                        <span className="prefix">
                            {panelPrefix}
                        </span>
                        { t(panel.title) }
                    </Label>
                    <div className='sub-section-body'>
                        {(panel.questions || []).map((question, index) => {
                            const displayQuestion = checkBasedOnConditions(
                                question, questions, answers
                            )

                            if(!displayQuestion) return null

                            questionIndex++

                            return (
                                <Question
                                    key={index}
                                    question={question}
                                    answer={findAnswer(question)}
                                    index={questionIndex}
                                    subSectionPrefix={panelPrefix}
                                    fileDownloaderOptions={settings?.fileDownloaderOptions || {}}
                                />
                            )
                        })}
                    </div>
                </div>
            )

        })
    }

    return (
        <div className='smartintegrity__questionnaire_answer_sheet'>
            <Row>
                <Col sm='12'>
                    <div className='sections-container'>
                        <Accordion 
                            defaultActiveKey={[0, 1, 2]}
                            alwaysOpen
                        >
                            {questions.filter((section) => {
                                const firstDisplableQuestionIndex = section.questions.findIndex((question) => {
                                    const displayQuestion = checkBasedOnConditions(
                                        question, questions, answers
                                    )

                                    return displayQuestion  
                                })
    
                                return firstDisplableQuestionIndex > -1
                            }).map((section, index) => {
                                return (
                                    <Accordion.Item 
                                        eventKey={ index }
                                    >
                                            <Accordion.Header>
                                                {QuestionnairePagesImages[section.icon]?.src ? (
                                                    <img src={QuestionnairePagesImages[section.icon]?.src} alt="" />
                                                ) : (
                                                    <i 
                                                        className={`${section.icon} page-icon`} 
                                                    />
                                                )}
                                                <span className="prefix">
                                                    {index + 1}.
                                                </span>
                                                <span>{t(section.title)}</span>
                                            </Accordion.Header>
                                            
                                            <Accordion.Body>
                                                { renderQuestions(section.panels, index + 1) }
                                            </Accordion.Body>
                                    </Accordion.Item>
                                   
                                )
                            })}
                        </Accordion>
                    </div>
                </Col>
            </Row>

            <Row className='mb-4'>
                <Col sm='12' className='d-flex justify-content-between align-items-center'>
                    {
                        settings?.buttons?.back?.enabled && (
                            <Button
                                onClick={() => {
                                    if(onBackButtonClicked){
                                        onBackButtonClicked()
                                    }
                                }}
                                color='secondary'
                                type='button'>
                                {settings.buttons.back?.title || t('Back')}
                            </Button>
                        )
                    }

                    {
                        settings?.buttons?.confirm?.enabled && (
                            <Button
                                onClick={() => {
                                    if(onConfirmed){
                                        onConfirmed()
                                    }
                                }}
                                color='btn btn-primary tpdd-btn'
                                type='button'>
                                {settings.buttons.confirm?.title || t('Confirm')}
                            </Button>
                        )
                    }
                </Col>
            </Row>
        </div>
    )
})