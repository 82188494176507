import * as React from 'react'
import { TextBox, TextArea, Date, CheckBox, RadioBox, Select } from './Inputs'
import { Row, Col } from 'reactstrap'
import { FileUploader } from './Inputs/FileUploader'
import { ConnectForm } from '../../ConnectForm'

const renderQuestionByType = ({
    question,
    settings,
    displayQuestion,
    questionTitlePrefix,
    ...rest
}) => {
    if (!question?.id || !question?.type) return null

    const questionName = question.parentQuestion
        ? `${question.parentQuestion}-${question.id}`
        : `${question.id}`

    switch (question.type) {
        case 'textarea':
            return (
                <TextArea
                    name={questionName}
                    label={question.title}
                    validation={{ required: !!question?.is_mandatory }}
                    displayQuestion={displayQuestion}
                    questionTitlePrefix={questionTitlePrefix}
                    {...rest}
                />
            )

        case 'date':
            return (
                <Date
                    name={questionName}
                    label={question.title}
                    validation={{ required: !!question?.is_mandatory }}
                    displayQuestion={displayQuestion}
                    questionTitlePrefix={questionTitlePrefix}
                    {...rest}
                />
            )

        case 'checkbox':
        case 'category':
            return (
                <CheckBox
                    name={questionName}
                    label={question.title}
                    validation={{ required: !!question?.is_mandatory }}
                    displayQuestion={displayQuestion}
                    options={question?.options || []}
                    questionTitlePrefix={questionTitlePrefix}
                    {...rest}
                />
            )

        case 'radiobox':
            return (
                <RadioBox
                    name={questionName}
                    label={question.title}
                    validation={{ required: !!question?.is_mandatory }}
                    options={question?.options || []}
                    questionTitlePrefix={questionTitlePrefix}
                    displayQuestion={displayQuestion}
                    subQuestionRenerer={(subQuestion) => {
                        return renderQuestionByType({
                            question: subQuestion,
                            settings: settings,
                            displayQuestion: true,
                            questionTitlePrefix: subQuestion.questionTitlePrefix,
                            ...rest
                        })
                    }}
                    {...rest}
                />
            )

        case 'select':
        case 'country_list':
            return (
                <Select
                    name={questionName}
                    label={question.title}
                    validation={{ required: !!question?.is_mandatory }}
                    options={question?.options || []}
                    displayQuestion={displayQuestion}
                    questionTitlePrefix={questionTitlePrefix}
                    subQuestionRenerer={(subQuestion) => {
                        return renderQuestionByType({
                            question: subQuestion,
                            settings: settings,
                            ...rest
                        })
                    }}
                    {...rest}
                />
            )

        case 'file_upload':
            return (
                <FileUploader
                    name={questionName}
                    label={question.title}
                    validation={{ required: !!question?.is_mandatory }}
                    displayQuestion={displayQuestion}
                    settings={settings?.fileUploaderOptions || {}}
                    questionTitlePrefix={questionTitlePrefix}
                    {...rest}
                />
            )

        default:
            return (
                <TextBox
                    name={questionName}
                    label={question.title}
                    validation={{ required: !!question?.is_mandatory }}
                    displayQuestion={displayQuestion}
                    questionTitlePrefix={questionTitlePrefix}
                    {...rest}
                />
            )
    }
}

const ReactStrapQuestion = ({ 
    question, 
    settings, 
    displayQuestion,
    questionTitlePrefix 
}) => {
    return (
        <ConnectForm>
            {({ ...method }) => {
                return (
                    <Row className='question-item'>
                        <Col sm='12'>
                            {renderQuestionByType({
                                question,
                                settings,
                                displayQuestion,
                                questionTitlePrefix,
                                ...method
                            })}
                        </Col>
                    </Row>
                )
            }}
        </ConnectForm>
    )
}

export { ReactStrapQuestion }
