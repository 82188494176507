import * as React from 'react'
import { withNamespaces } from 'react-i18next'
import { FormGroup, Label, Input, FormFeedback } from 'reactstrap'
import { Controller } from 'react-hook-form'

const TextBox = withNamespaces()(({
    name,
    label,
    validation,
    displayQuestion,
    questionTitlePrefix,
    t,
    ...rest
}) => {
    const {
        control,
        formState: { errors, defaultValues }
    } = rest

    const inputCurrentValueRef = React.useRef(defaultValues[name])
    
    React.useEffect(() => {
        control.register(name, {
            required: !!validation?.required
        })
        
        return () => {
            control.unregister(name)
        }
    }, [])

    React.useEffect(() => {
        if(!displayQuestion){
            control.unregister(name)
        }
        else{
            control.register(name, {
                required: !!validation?.required
            })
        }
    }, [ displayQuestion ])

    if(!displayQuestion) return null

    return (
        <FormGroup>
            {label && (
                <Label for={name}>
                    {questionTitlePrefix && (
                        <span className="prefix">
                            { questionTitlePrefix }
                        </span>
                    )}
                    
                    {t(label)}
                </Label>
            )}

            <Controller
                name={name}
                control={control}
                render={({ field: {
                    onChange,
                    onBlur,
                    value,
                    ...fieldElseProps
                } }) => (
                    <Input
                        {...fieldElseProps}
                        defaultValue={value}
                        onBlur={(e) => {
                            onBlur(e)
                            if(e.target.value !== inputCurrentValueRef?.current){
                                onChange(e)
                                inputCurrentValueRef.current = e.target.value
                            }
                        }}
                        type='text'
                        id={name}
                    />
                )}
            />
            {errors && errors[name] && (
                <FormFeedback className='d-block' valid={false}>
                    {t(`This field cannot be blank`)}
                </FormFeedback>
            )}
        </FormGroup>
    )
})

export { TextBox }
