const QuestionnairePagesImages = {
    anti_corruption: {
        src: '/images/questionnaire/pages/anti_corruption.svg',
        title: 'Anti Corruption'
    },
    compliance_and_corporate_policy: {
        src: '/images/questionnaire/pages/compliance_and_corporate_policy.svg',
        title: 'Compliance & Corporate Policy'
    },
    environmental_standards: {
        src: '/images/questionnaire/pages/environmental_standards.svg',
        title: 'Environmental Standards'
    },
    human_rights_due_diligence: {
        src: '/images/questionnaire/pages/human_rights_due_diligence.svg',
        title: 'Human Rights Due Diligence'
    },
    relevant_certificates_and_audit_reports: {
        src: '/images/questionnaire/pages/relevant_certificates_and_audit_reports.svg',
        title: 'Relevant Certificates & Audit Reports'
    },
    risk_management: {
        src: '/images/questionnaire/pages/risk_management.svg',
        title: 'Risk Management'
    },
    transparency_and_reporting: {
        src: '/images/questionnaire/pages/transparency_and_reporting.svg',
        title: 'Transparency & Reporting'
    },
    working_conditions: {
        src: '/images/questionnaire/pages/working_conditions.svg',
        title: 'Working Conditions'
    },
    final_declarations: {
        src: '/images/questionnaire/pages/final_declarations.svg',
        title: 'Final Declarations'
    },
    financials: {
        src: '/images/questionnaire/pages/financials.svg',
        title: 'Financials'
    },
    applications_and_interface_security: {
        src: '/images/questionnaire/pages/applications_interface_security.png',
        title: 'Applications Interface Security'
    },
    audit_assurance_and_compliance: {
        src: '/images/questionnaire/pages/audit_assurance_and_compliance.png',
        title: 'Audit Assurance & compliance'
    },
    business_continuity_management_and_operational_resilience: {
        src: '/images/questionnaire/pages/business_continuity_management_and_operational_resilience.png',
        title: 'Business Continuity Management & Operational Resilience'
    },
    change_management: {
        src: '/images/questionnaire/pages/change_management.png',
        title: 'Change Managementg'
    },
    data_security_and_information_lifecycle_management: {
        src: '/images/questionnaire/pages/data_security_and_information_lifecycle_management.png',
        title: 'Data Security & Information Lifecycle Managemenpng'
    },
    data_center_security: {
        src: '/images/questionnaire/pages/data_center_security.png',
        title: 'Data Center Security'
    },
    encryption_and_key_management: {
        src: '/images/questionnaire/pages/encryption_and_key_management.png',
        title: 'Encryption & Key Management'
    },
    governance_and_risk_management: {
        src: '/images/questionnaire/pages/governance_and_risk_management.png',
        title: 'Governance & Risk Management'
    },
    human_resources: {
        src: '/images/questionnaire/pages/human_resources.png',
        title: 'Human Resources'
    },
    identity_and_access_management: {
        src: '/images/questionnaire/pages/identity_and_access_management.png',
        title: 'Identity & Access Management'
    },
    infrastructure_and_virtualization_security: {
        src: '/images/questionnaire/pages/infrastructure_and_virtualization_security.png',
        title: 'Infrastructure & virtualization Security'
    },
    interoperability_and_portability: {
        src: '/images/questionnaire/pages/interoperability_and_portability.png',
        title: 'Interoperability & Portability'
    },
    security_incident_management_e_discovery_and_cloud_forensics: {
        src: '/images/questionnaire/pages/security_incident_management_e_discovery_and_cloud_forensics.png',
        title: 'Security Incident Management & Cloud Forensics'
    },
    supply_change_management_transparency_and_accountability: {
        src: '/images/questionnaire/pages/supply_change_management_transparency_and_accountability.png',
        title: 'Supply Change Management Transparency & Accountability'
    },
    threat_and_vulnerability_management: {
        src: '/images/questionnaire/pages/threat_and_vulnerability_management.png',
        title: 'Threat & Vulnerability Management'
    },
    external_certifications: {
        src: '/images/questionnaire/pages/external_certifications.png',
        title: 'External Certifications'
    }
}

const FileTypesAndImages = {
    image: {
        image: '/images/questionnaire/file-types/image.svg',
        types: ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'tiff', 'webp']
    },
    pdf: {
        image: '/images/questionnaire/file-types/pdf.svg',
        types: ['pdf']
    },
    excel: {
        image: '/images/questionnaire/file-types/excel.svg',
        types: ['xls', 'xlsx']
    },
    word: {
        image: '/images/questionnaire/file-types/word.svg',
        types: ['doc', 'docx']
    },
    powerpoint: {
        image: '/images/questionnaire/file-types/powerpoint.svg',
        types: ['ppt', 'pptx']
    },
    text: {
        image: '/images/questionnaire/file-types/text.svg',
        types: ['txt', 'csv', 'log']
    },
    video: {
        image: '/images/questionnaire/file-types/video.svg',
        types: ['mp4', 'mkv', 'avi', 'mov', 'wmv', 'flv', 'webm']
    },
    audio: {
        image: '/images/questionnaire/file-types/audio.svg',
        types: ['mp3', 'wav', 'aac', 'ogg', 'flac']
    },
    archive: {
        image: '/images/questionnaire/file-types/archive.svg',
        types: ['zip', 'rar', '7z', 'tar', 'gz']
    },
    code: {
        image: '/images/questionnaire/file-types/code.svg',
        types: ['js', 'html', 'css', 'json', 'xml', 'py', 'java', 'c', 'cpp', 'ts']
    },
    unknown: {
        image: '/images/questionnaire/file-types/empty.svg',
        types: []
    }
}

const QuestionnaireEmptyImageSrc = '/images/questionnaire/pages/empty.png'

export { 
    QuestionnairePagesImages, 
    QuestionnaireEmptyImageSrc, 
    FileTypesAndImages 
}