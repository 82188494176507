import * as React from 'react'
import { withNamespaces } from 'react-i18next'
import ReactDragListView from 'react-drag-listview'
import { Page } from './components/Page'
import {
    Col,
    Row,
    ListGroup,
    ListGroupItem
} from 'reactstrap'
import { PageContext } from './Context/PageContext'
import { RuleEditorContext } from './Context/RuleEditorContext'
import {
    QuestionTypes,
    SubQuestionTypes
} from './constants'
import '../assets/scss/designer/index.scss'
import RulesEditorModal from './components/Common/RulesEditorModal'
import SaveQuestionnaire from './components/SaveQuestionnaire'
import IconSelector from './components/Common/IconSelector'

export const QuestionnaireDesigner = withNamespaces()(({
    defaultModel,
    questionnaireBasicData,
    onSaveButtonClicked,
    isLoading,
    savingInprocess,
    t,
    lng
}) => {
    const [firstInitCompleted, setFirstInitCompleted] = React.useState(false)

    const [elements, setElements] = React.useState([])

    const elementsRefs = React.useRef(new Map())

    const [elementsTree, setElementsTree] = React.useState([])

    const [currentEditingPage, setCurrentEditingPage] = React.useState(null)

    const [showPageImageSelectorModal, setShowPageImageSelectorModal] =
        React.useState(null)

    const [ruleEditorCurrentElementID, setRuleEditorCurrentElementID] = React.useState(null)

    const showRuleEditorModal = (elID) => setRuleEditorCurrentElementID(elID)
    const hideRuleEditorModal = () => setRuleEditorCurrentElementID(null)

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list)
        const [removed] = result.splice(startIndex, 1)
        result.splice(endIndex, 0, removed)
        return result
    }

    const findParentAndReSort = (parentId, children, fromIndex, toIndex) => {
        for (let i = 0; i < children.length; i++) {
            if (children[i].id === parentId) {
                children[i].elements = reorder(
                    children[i].elements,
                    fromIndex,
                    toIndex
                )
                return
            }

            if (children[i].elements && children[i].elements.length > 0) {
                findParentAndReSort(
                    parentId,
                    children[i].elements,
                    fromIndex,
                    toIndex
                )
            }
        }
    }

    const onDragEnd = (fromIndex, toIndex, parentId = null) => {
        if (toIndex < 0) return

        setElementsTree((currentState) => {
            const children = [...currentState]

            if (!parentId) {
                return reorder(children, fromIndex, toIndex)
            }

            findParentAndReSort(parentId, children, fromIndex, toIndex)

            return children
        })
    }

    const generateRandomID = () => {
        return `el-${Date.now()}-${Math.random()}`
    }

    const scrollToElement = (elementType, id) => {
        setTimeout(() => {
            const elRef = elementsRefs.current.get(id)
            if (elRef) {
                elRef.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                    inline: 'nearest'
                })
                elRef.classList.add('smartintegrity__questionnaire__designer-item-notify')
                setTimeout(() => {
                    elRef.classList.remove('smartintegrity__questionnaire__designer-item-notify')
                }, 1500)
            }
        }, 500)
    }

    const findParentAndPushChild = (parentId, children, item) => {
        if (!parentId) {
            children.push(item)
            return
        }

        for (let i = 0; i < children.length; i++) {
            if (children[i].id === parentId) {
                if (!children[i]?.elements) {
                    children[i].elements = [item]
                } else {
                    children[i].elements.push(item)
                }
                return
            }

            if (children[i].elements && children[i].elements.length > 0) {
                findParentAndPushChild(parentId, children[i].elements, item)
            }
        }
    }

    const findAndDeleteChildFromTree = (elementID, children) => {
        for (let i = 0; i < children.length; i++) {
            if (children[i].id === elementID) {
                children.splice(i, 1)
                return
            }

            if (children[i].elements && children[i].elements.length > 0) {
                findAndDeleteChildFromTree(elementID, children[i].elements)
            }
        }
    }

    const addElementToTree = (elementType, elementProps) => {
        setElementsTree((currentState) => {
            const children = [...currentState]

            findParentAndPushChild(elementProps.parentElementID, children, {
                type: elementType,
                ...elementProps
            })
            return children
        })
    }

    const deleteElementFromTree = (elementID) => {
        setElementsTree((currentState) => {
            const children = [...currentState]
            findAndDeleteChildFromTree(elementID, children)
            return children
        })
    }

    const deleteFromElements = (elementID) => {
        setElements((currentState) => {
            const newState = [...currentState]

            const elementIndex = newState.findIndex((el) => el.id === elementID)

            if (elementIndex >= 0) {
                if (
                    newState[elementIndex].elementType === 'page' &&
                    currentEditingPage === newState[elementIndex].id
                ) {
                    setCurrentEditingPage(null)
                }
                newState.splice(elementIndex, 1)
            }

            return newState
        })
    }

    const addElement = (elementType, elseProps) => {
        const id = generateRandomID()

        setElements((currentState) => {
            const newState = [...currentState]

            newState.push({
                id: id,
                elementType: elementType,
                state: {
                    editTitle: false,
                    collapsed: false
                },
                ...elseProps
            })

            if (elseProps.parentElementID) {
                const parentIndex = newState.findIndex(
                    (el) => el.id === elseProps.parentElementID
                )
                if (parentIndex > -1) {
                    newState[parentIndex].state.collapsed = true
                }
            }

            return newState
        })

        addElementToTree(elementType, {
            ...elseProps,
            id: id
        })

        scrollToElement(elementType, id)

        return id
    }

    const deleteElement = (elementID) => {
        deleteElementFromTree(elementID)
        deleteFromElements(elementID)
    }

    const setElementField = (elementID, field, value) => {
        setElements((currentState) => {
            const newState = [...currentState]

            const elementIndex = newState.findIndex((el) => el.id === elementID)

            if (elementIndex >= 0) {
                newState[elementIndex][field] = value
            }

            return newState
        })
    }

    const setElementStateField = (elementID, field, value) => {
        setElements((currentState) => {
            const newState = [...currentState]

            const elementIndex = newState.findIndex((el) => el.id === elementID)

            if (elementIndex >= 0) {
                if (!newState[elementIndex]?.state) {
                    newState[elementIndex].state = {
                        [field]: value
                    }
                } else {
                    newState[elementIndex].state[field] = value
                }
            }

            return newState
        })
    }

    const addQuestionOption = (questionID, optionSettings) => {
        const newOptionSettings = {
            ...optionSettings,
            id: generateRandomID()
        }

        setElements((currentState) => {
            const newState = [...currentState]

            const questionIndex = newState.findIndex(
                (el) => el.id === questionID
            )

            if (questionIndex >= 0) {
                if (!newState[questionIndex].options) {
                    newState[questionIndex].options = [newOptionSettings]
                } else {
                    newState[questionIndex].options.push(newOptionSettings)
                }
            }

            return newState
        })
    }

    const deleteQuestionOption = (questionID, optionIndex) => {
        setElements((currentState) => {
            const newState = [...currentState]

            const questionIndex = newState.findIndex(
                (el) => el.id === questionID
            )

            if (questionIndex >= 0) {
                newState[questionIndex].options.splice(optionIndex, 1)
            }

            return newState
        })
    }

    const setQuestionOptionField = (questionID, optionIndex, field, value) => {
        setElements((currentState) => {
            const newState = [...currentState]

            const elementIndex = newState.findIndex(
                (el) => el.id === questionID
            )

            if (elementIndex >= 0) {
                newState[elementIndex].options[optionIndex][field] = value
            }

            return newState
        })
    }

    const setQuestionOptionStateField = (
        questionID,
        optionIndex,
        field,
        value
    ) => {
        setElements((currentState) => {
            const newState = [...currentState]

            const elementIndex = newState.findIndex(
                (el) => el.id === questionID
            )

            if (elementIndex >= 0) {
                if (!newState[elementIndex].options[optionIndex].state) {
                    newState[elementIndex].options[optionIndex].state = {
                        [field]: value
                    }
                } else {
                    newState[elementIndex].options[optionIndex].state[field] =
                        value
                }
            }

            return newState
        })
    }

    const addQuestionOptionChild = (questionID, optionIndex) => {
        setElements((currentState) => {
            const newState = [...currentState]

            const elementIndex = newState.findIndex(
                (el) => el.id === questionID
            )

            if (elementIndex >= 0) {
                const option = newState[elementIndex].options[optionIndex]
                const childNum =
                    newState[elementIndex].options[optionIndex].children
                        .length + 1

                const subQType =
                    option.state?.newSubQuestionType ||
                    SubQuestionTypes.find((sType) => sType.isDefault)

                newState[elementIndex].options[optionIndex].children.push({
                    id: generateRandomID(),
                    title: `${t('Sub question')} ${childNum}`,
                    placeholder: subQType.placeholder,
                    questionType: subQType.value,
                    isMandatory: false,
                    isDeletable: true,
                    state: {
                        editTitle: false
                    },
                    options: []
                })
            }

            return newState
        })
    }

    const deleteQuestionOptionChild = (questionID, optionIndex, childIndex) => {
        setElements((currentState) => {
            const newState = [...currentState]

            const questionIndex = newState.findIndex(
                (el) => el.id === questionID
            )

            if (questionIndex >= 0) {
                newState[questionIndex].options[optionIndex].children.splice(
                    childIndex,
                    1
                )
            }

            return newState
        })
    }

    const setQuestionOptionChildField = (
        questionID,
        optionIndex,
        childIndex,
        field,
        value
    ) => {
        setElements((currentState) => {
            const newState = [...currentState]

            const questionIndex = newState.findIndex(
                (el) => el.id === questionID
            )

            if (questionIndex >= 0) {
                newState[questionIndex].options[optionIndex].children[
                    childIndex
                ][field] = value
            }

            return newState
        })
    }

    const setQuestionOptionChildStateField = (
        questionID,
        optionIndex,
        childIndex,
        field,
        value
    ) => {
        setElements((currentState) => {
            const newState = [...currentState]

            const questionIndex = newState.findIndex(
                (el) => el.id === questionID
            )

            if (questionIndex >= 0) {
                if (
                    !newState[questionIndex].options[optionIndex].children[
                        childIndex
                    ].state
                ) {
                    newState[questionIndex].options[optionIndex].children[
                        childIndex
                    ].state = {
                        [field]: value
                    }
                } else {
                    newState[questionIndex].options[optionIndex].children[
                        childIndex
                    ].state[field] = value
                }
            }

            return newState
        })
    }

    const addSubQuestionOption = (
        questionID,
        optionIndex,
        childIndex,
        optionSettings
    ) => {
        setElements((currentState) => {
            const newState = [...currentState]

            const questionIndex = newState.findIndex(
                (el) => el.id === questionID
            )

            if (questionIndex >= 0) {
                if (
                    !newState[questionIndex].options[optionIndex].children[
                        childIndex
                    ].options
                ) {
                    newState[questionIndex].options[optionIndex].children[
                        childIndex
                    ].options = [optionSettings]
                } else {
                    newState[questionIndex].options[optionIndex].children[
                        childIndex
                    ].options.push(optionSettings)
                }
            }

            return newState
        })
    }

    const deleteSubQuestionOption = (
        questionID,
        optionIndex,
        childIndex,
        subOptionIndex
    ) => {
        setElements((currentState) => {
            const newState = [...currentState]

            const questionIndex = newState.findIndex(
                (el) => el.id === questionID
            )

            if (questionIndex >= 0) {
                newState[questionIndex].options[optionIndex].children[
                    childIndex
                ].options.splice(subOptionIndex, 1)
            }

            return newState
        })
    }

    const setSubQustionOptionField = (
        questionID,
        optionIndex,
        childIndex,
        subOptionIndex,
        field,
        value
    ) => {
        setElements((currentState) => {
            const newState = [...currentState]

            const elementIndex = newState.findIndex(
                (el) => el.id === questionID
            )

            if (elementIndex >= 0) {
                newState[elementIndex].options[optionIndex].children[
                    childIndex
                ].options[subOptionIndex][field] = value
            }

            return newState
        })
    }

    const setSubQustionOptionStateField = (
        questionID,
        optionIndex,
        childIndex,
        subOptionIndex,
        field,
        value
    ) => {
        setElements((currentState) => {
            const newState = [...currentState]

            const elementIndex = newState.findIndex(
                (el) => el.id === questionID
            )

            if (elementIndex >= 0) {
                if (
                    !newState[elementIndex].options[optionIndex].children[
                        childIndex
                    ].options[subOptionIndex].state
                ) {
                    newState[elementIndex].options[optionIndex].children[
                        childIndex
                    ].options[subOptionIndex].state = {
                        [field]: value
                    }
                } else {
                    newState[elementIndex].options[optionIndex].children[
                        childIndex
                    ].options[subOptionIndex].state[field] = value
                }
            }

            return newState
        })
    }

    const handleAddNewQuestionToActivePage = (qType) => {
        let pageID = currentEditingPage
        if (!pageID) {
            pageID = elements.filter((el) => el.elementType === 'page')[0]?.id

            if (!pageID) {
                pageID = addElement('page', {
                    title: 'Tab Title',
                    image: null
                })
            }
        }

        setTimeout(() => {
            addElement('question', {
                questionType: qType.value,
                title: t('Question Title'),
                placeholder: qType.placeholder,
                parentElementID: pageID
            })
        }, 250)
    }

    const handleAddNewPage = () => {
        addElement('page', {
            title: 'Tab Title',
            image: null
        })
    }

    const handleAddNewPanelToActivePage = () => {
        let pageID = currentEditingPage
        if (!pageID) {
            pageID = elements.filter((el) => el.elementType === 'page')[0]?.id
            if (!pageID) {
                pageID = addElement('page', {
                    title: t('Tab Title'),
                    image: null
                })
            }
        }

        setTimeout(() => {
            addElement('panel', {
                title: t('Panel Title'),
                parentElementID: pageID
            })
        }, 250)
    }

    const getTreeElements = (treeElements) => {
        const result = []
        for (let i = 0; i < treeElements.length; i++) {
            const item = treeElements[i]
            const itemInfos = elements.find((el) => el.id === item.id)

            if (itemInfos) {
                const itemTree = {
                    ...itemInfos,
                    elementType: itemInfos.elementType
                }

                if (item.elements && item.elements.length > 0) {
                    itemTree.elements = getTreeElements(item.elements)
                }

                result.push(itemTree)
            }
        }

        return result
    }

    const handleSaveQuestionnaire = ({ title }) => {
        onSaveButtonClicked &&
            onSaveButtonClicked({
                title: title,
                elements: getTreeElements(elementsTree)
            })
    }

    const initQuestionnaire = () => {
        const elementsResult = []
        const treeResult = []

        const findAndInit = (children, parentElementID = undefined) => {
            for (let i = 0; i < children.length; i++) {
                const el = children[i]
                const id = el.id || generateRandomID()

                elementsResult.push({
                    ...el,
                    id: id,
                    dbID: el.dbID,
                    elementType: el.elementType,
                    state: {
                        editTitle: false,
                        collapsed: false
                    },
                    elements: []
                })

                findParentAndPushChild(parentElementID, treeResult, {
                    type: el.elementType,
                    id: id,
                    dbID: el.dbID,
                    elements: []
                })

                if (el?.elements?.length > 0) {
                    findAndInit(el.elements, id)
                }
            }
        }

        findAndInit(defaultModel)

        if (elementsResult.length > 0) {
            elementsResult[0].state.collapsed = true

            setElements(elementsResult)
            setElementsTree(treeResult)
            setCurrentEditingPage(elementsResult[0].id)
        }
    }

    React.useEffect(() => {
        if (!isLoading && defaultModel?.length > 0 && !firstInitCompleted) {
            initQuestionnaire()
            setFirstInitCompleted(true)
        }
    }, [isLoading, defaultModel, firstInitCompleted])

    React.useEffect(() => {
        if(firstInitCompleted && !isLoading && defaultModel?.length > 0){
            initQuestionnaire()
        }
    }, [ isLoading, defaultModel ])

    React.useEffect(() => {
        if(elements.length > 0){
            setElements((currentState) => {
                return [...currentState].map((el) => {
                    return {
                        ...el,
                        title: t(el.baseTitle || el.title),
                        options: (el.options || []).map((option) => {
                            return {
                                ...option,
                                title: t(option.baseTitle || option.title),
                                children: (option.children || []).map((child) => {
                                    return {
                                        ...child,
                                        title: t(child.baseTitle || child.title),
                                        options: (child.options || []).map((childOption) => {
                                            return {
                                                ...childOption,
                                                title: t(childOption.baseTitle || childOption.title)
                                            }
                                        })
                                    }
                                })
                            }
                        })
                    }
                })
            });
        }
    }, [t])

    const resultPages = elementsTree.map((treeItem) => {
        return {
            ...elements.find((element) => element.id === treeItem.id),
            elements: treeItem.elements
        }
    })

    if (elementsRefs.current.size !== elements.length) {
        elements.forEach((el) => {
            if (!elementsRefs.current.has(el.id)) {
                elementsRefs.current.set(el.id, React.createRef())
            }
        })
    }

    const colSize = lng !== 'en' ? {
        sm: '12'
    } : {
        sm: '8', 
        md: '8', 
        lg: '9'
    }

    return (
        <div className='smartintegrity__questionnaire__designer'>
            {!isLoading ? (
                <PageContext.Provider
                    value={{
                        elements,
                        elementsTree,
                        elementsRefs,
                        currentEditingPage,

                        setCurrentEditingPage,

                        setElements,
                        addElement,
                        deleteElement,
                        setElementField,
                        setElementStateField,

                        addQuestionOption,
                        deleteQuestionOption,
                        setQuestionOptionField,
                        setQuestionOptionStateField,

                        addQuestionOptionChild,
                        setQuestionOptionChildField,
                        deleteQuestionOptionChild,
                        setQuestionOptionChildStateField,

                        addSubQuestionOption,
                        setSubQustionOptionField,
                        setSubQustionOptionStateField,
                        deleteSubQuestionOption,

                        onDragEnd,
                        reorder
                    }}
                >
                    <RuleEditorContext.Provider
                        value={{
                            elementID: ruleEditorCurrentElementID,
                            showModal: showRuleEditorModal,
                            hideModal: hideRuleEditorModal
                        }}
                    >
                        <div className='smartintegrity__questionnaire__designer-container'>
                            <Row className='d-flex justify-content-end sticky-saver mb-2'>
                                <Col sm='12' md='6'>
                                    <SaveQuestionnaire 
                                        title={ questionnaireBasicData?.title }
                                        onSaveButtonClicked={ handleSaveQuestionnaire }
                                        savingInprocess={ savingInprocess }
                                    />
                                </Col>
                            </Row>

                            <Row>
                                <Col sm='4' md='4' lg='3' className={`${lng !== 'en' ? 'd-none' : ''}`}>
                                    <div className='smartintegrity__questionnaire__designer-questions-types-container'>
                                        <ListGroup flush>
                                            <ListGroupItem
                                                onClick={handleAddNewPage}
                                                action
                                                key='new-page'
                                                className='d-flex align-items-center'
                                            >
                                                <i className='ri-page-separator me-2' />
                                                {t('New Questions Tab')}
                                            </ListGroupItem>

                                            <ListGroupItem
                                                onClick={
                                                    handleAddNewPanelToActivePage
                                                }
                                                action
                                                key='new-panel'
                                                className='d-flex align-items-center'
                                            >
                                                <i className='ri-fullscreen-line me-2' />
                                                {t('New Panel')}
                                            </ListGroupItem>

                                            {QuestionTypes.map((qType, index) => {
                                                return (
                                                    <ListGroupItem
                                                        onClick={() =>
                                                            handleAddNewQuestionToActivePage(
                                                                qType
                                                            )
                                                        }
                                                        action
                                                        key={index}
                                                        className='d-flex align-items-center'
                                                    >
                                                        <i
                                                            className={`${qType.icon} me-2`}
                                                        />
                                                        {t(qType.title)}
                                                    </ListGroupItem>
                                                )
                                            })}
                                        </ListGroup>
                                    </div>
                                </Col>
                                
                                <Col {...colSize}>
                                    <Row>
                                        <Col sm='12'>
                                            <ReactDragListView
                                                nodeSelector='.smartintegrity__questionnaire__designer-page'
                                                handleSelector='.smartintegrity__questionnaire__designer-drag-btn.page'
                                                onDragEnd={(fromIndex, toIndex) => {
                                                    onDragEnd(fromIndex, toIndex)
                                                }}
                                                lineClassName='smartintegrity__questionnaire__designer-page-placeholder'
                                            >
                                                {resultPages.map(
                                                    (page, pageIndex) => {
                                                        return (
                                                            <Page
                                                                key={pageIndex}
                                                                {...page}
                                                                pageIndex={pageIndex}
                                                                onChangeImageBtnClicked={(
                                                                    pageInfo
                                                                ) => {
                                                                    setShowPageImageSelectorModal(
                                                                        pageInfo
                                                                    )
                                                                }}
                                                            />
                                                        )
                                                    }
                                                )}
                                            </ReactDragListView>

                                            {/* <div>
                                                <button
                                                    onClick={handleAddNewPage}
                                                    className='smartintegrity__questionnaire__designer-add-new-page-btn'
                                                >
                                                    {t('Add New Questions Tab')}
                                                </button>
                                            </div> */}
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                            <IconSelector 
                                showModal={!!showPageImageSelectorModal}
                                hideModal={() => setShowPageImageSelectorModal(null)}
                                defaultValue={showPageImageSelectorModal?.image}
                                onIconSelect={(pageImage) => {
                                    setElementField(
                                        showPageImageSelectorModal.id,
                                        'image',
                                        pageImage
                                    )
                                    setTimeout(() => {
                                        setShowPageImageSelectorModal(
                                            null
                                        )
                                    }, 100)
                                }}
                            />

                            <RulesEditorModal />
                        </div>
                    </RuleEditorContext.Provider>
                </PageContext.Provider>
            ) : (
                <Row>
                    <Col sm='4' md='3'>
                        <div
                            style={{ width: '100%', height: '450px' }}
                            className='dt-field dt-skeleton'
                        />
                    </Col>
                    <Col sm='8' md='9'>
                        <div
                            style={{ width: '100%', height: '600px' }}
                            className='dt-field dt-skeleton'
                        />
                    </Col>
                </Row>
            )}
        </div>
    )
})
