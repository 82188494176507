import * as React from 'react'
import { withNamespaces } from 'react-i18next'
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    UncontrolledAlert
} from 'reactstrap'
import ReactSelect from 'react-select'
import { PageContext } from '../../../Context/PageContext'
import { RuleEditorContext } from '../../../Context/RuleEditorContext'

const RulesEditorModal = ({
    t
}) => {
    const {
        elements,
        setElementField
    } = React.useContext(PageContext)

    const {
        elementID,
        hideModal
    } = React.useContext(RuleEditorContext)

    const [selectedQuestionOptions, setSelectedQuestionOptions] = React.useState([])
    const [selectedQuestion, setSelectedQuestion] = React.useState(null)
    const [selectedOption, setSelectedOption] = React.useState(null)
    const [condition, setCondition] = React.useState('always')

    const OtherQuestions = React.useMemo(() => {
        if(!elementID) return []

        const elIndex = elements.findIndex((el) => el.id === elementID)

        return elements.filter((el, index) => {
            return (
                el.elementType === 'question' && 
                el.id !== elementID &&
                [
                    'radiobox',
                    'select'
                ].includes(el.questionType) &&
                index < elIndex
            )
        }).map((el) => {
            return {
                value: el.id,
                label: el.title,
                baseLabel: el.baseTitle,
                elementOptions: el.options || []
            }
        })
    }, [elementID])

    React.useEffect(() => {
        if(elementID) {
            const element = elements.find((el) => el.id === elementID)

            setCondition(element?.basedOn ? 'if' : 'always')

            if(element?.basedOn?.question){
                const basedOnQuestion = OtherQuestions.find((el) => {
                    return (el.value === element.basedOn.question)
                })

                if(basedOnQuestion){
                    setSelectedQuestion(basedOnQuestion)
                    setSelectedOption(element.basedOn.option)
                }
            }
        }
    }, [elementID])

    React.useEffect(() => {
        if(selectedQuestion){
            setSelectedQuestionOptions(selectedQuestion.elementOptions.map((op) => {
                return {
                    value: op.id,
                    label: op.title,
                    baseLabel: op.baseTitle
                }
            }))
        }
    }, [selectedQuestion])

    const handleChangeQuestion = (e) => {
        setSelectedQuestion(e)
    }

    const handleSelectOption = (e) => {
        setSelectedOption(e.target.value)
    }

    const saveRules = () => {
        setElementField(elementID, 'basedOn', condition === 'always' ? undefined : {
            question: selectedQuestion.value,
            option: selectedOption    
        })
        hideModal()
    }

    const element = elements.find((el) => el.id === elementID)

    return (
        <Modal
            className='smartintegrity__questionnaire__designer-container-rules-editor-modal'
            isOpen={elementID}
            toggle={hideModal}
            size='lg'
        >
            <ModalHeader
                toggle={hideModal}
            >
                {t('Conditional Display')}
            </ModalHeader>

            <ModalBody>
                <UncontrolledAlert dis color="info" className="d-flex justify-content-start">
                    <div className="d-flex align-items-center me-2">
                        <i className="ri-information-line" style={{fontSize: '32px'}}/>
                    </div>
                    <div className="d-flex align-items-start justify-content-center flex-column">
                        <strong>{t('Notice')}!</strong>
                        {t('Set conditions here to show or hide the selected question.')}
                    </div>
                </UncontrolledAlert>

                <div className='rules-container'>
                    <div className='rules-base-question'>
                        <p>
                            <span className='text-info'>{t('Display')}: </span>
                            {element?.title}
                        </p>
                    </div>

                    <div className='rules-items-container'>
                        <div className='rule-item'>
                            <div className='rule-item-condition-container'>
                                <select 
                                    className='form-control'
                                    onChange={(e) => setCondition(e.target.value)}
                                    value={condition}
                                >
                                    <option value='always'>
                                        {t('Always')}
                                    </option>
                                    <option value='if'>
                                        {t('If')}
                                    </option>
                                </select>
                            </div>

                            {condition === 'if' && (
                                <div className='rule-item-options-container'>
                                    <div className='rule-item-else-questions-container'>
                                        <ReactSelect
                                            name='prevQuestions'
                                            id='prevQuestions'
                                            placeholder={`${t('Select a question with type radio group or select box')}...`}
                                            isClearable={false}
                                            options={OtherQuestions}
                                            onChange={handleChangeQuestion}
                                            value={selectedQuestion}
                                        />
                                    </div>

                                    <div className='rule-item-else-options-container'>
                                        <div>
                                            <select className='form-control'>
                                                <option>{t('is equal to')}</option>
                                            </select>
                                        </div>

                                        <div>
                                            <select 
                                                className='form-control'
                                                onChange={handleSelectOption}
                                                value={selectedOption}
                                            >
                                                <option value="" disabled selected>
                                                    {t('Select an option')}
                                                </option>
                                                {selectedQuestionOptions.map((op) => {
                                                    return (
                                                        <option value={op.value}>
                                                            {op.label}
                                                        </option>
                                                    )
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                </div>  
                            )}
                        </div>
                    </div>
                </div>
            </ModalBody>

            <ModalFooter>
                <Button 
                    color='danger'
                    onClick={hideModal}
                >
                    {t('Cancel')}
                </Button>

                <Button 
                    color='primary'
                    onClick={saveRules}
                    disabled={!OtherQuestions.length && condition === 'if'}
                >
                    {t('Save')}
                </Button>
            </ModalFooter>
        </Modal>
    )
}

export default withNamespaces()(
    RulesEditorModal
);