import * as React from 'react'
import { withNamespaces } from 'react-i18next'
import {
    Input,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from 'reactstrap'
import ReactDragListView from 'react-drag-listview'
import { PageContext } from '../../Context/PageContext'
import { Question } from '../Question'
import { QuestionTypes, QuestionnairePagesImages, QuestionnaireEmptyImageSrc } from '../../constants'
import { Panel } from '../Panel'

const Page = withNamespaces()(({ t, lng, onChangeImageBtnClicked, pageIndex, ...elseProps }) => {
    const {
        elementsRefs,
        elements,
        deleteElement,
        setElementField,
        setElementStateField,

        currentEditingPage,
        setCurrentEditingPage,

        addElement,

        onDragEnd
    } = React.useContext(PageContext)

    const defaultNewQuestionType = QuestionTypes.find((t) => t.isDefault)

    const [newQuestionType, setNewQuestionType] = React.useState(
        defaultNewQuestionType
    )

    const saveTitle = (value) => {
        setElementField(elseProps.id, 'title', value)
        setElementStateField(elseProps.id, 'editTitle', false)
    }

    const childElements = (elseProps.elements || []).map((treeItem) => {
        return {
            ...elements.find((element) => element?.id === treeItem?.id),
            elements: treeItem.elements
        }
    })

    return (
        <div
            className={`smartintegrity__questionnaire__designer-page ${
                currentEditingPage === elseProps.id && 'active'
            }`}
            onClick={() => setCurrentEditingPage(elseProps.id)}
            ref={(ref) =>
                elementsRefs?.current &&
                elementsRefs.current.has(elseProps.id) &&
                elementsRefs.current.set(elseProps.id, ref)
            }
        >
            <span className='smartintegrity__questionnaire__designer-drag-btn page'>
                <i className='ri-drag-move-2-fill' />
            </span>

            <div className='smartintegrity__questionnaire__designer-page-actions'>
                <button
                    className={`text-danger ${lng !== 'en' && 'd-none'}`}
                    onClick={(e) => {
                        e.stopPropagation()
                        deleteElement(elseProps.id)
                    }}
                >
                    <i className='ri-delete-bin-line me-2' />
                    {t('Delete')}
                </button>

                <button
                    className='text-dark'
                    onClick={() => {
                        setElementStateField(
                            elseProps.id,
                            'collapsed',
                            !elseProps.state?.collapsed
                        )
                    }}
                >
                    {elseProps.state?.collapsed ? (
                        <React.Fragment>
                            <i className='ri-arrow-up-s-line' />
                            {t('Close')}
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <i className='ri-arrow-down-s-line' />
                            {t('Open')}
                        </React.Fragment>
                    )}
                </button>
            </div>

            <div className='smartintegrity__questionnaire__designer-page-title'>
                <button
                    onClick={() =>
                        lng === 'en' && 
                        onChangeImageBtnClicked &&
                        onChangeImageBtnClicked(elseProps)
                    }
                    className={`smartintegrity__questionnaire__designer-page-image-selector ${lng !== 'en' ? 'disabled' : ''}`}
                >
                    {!elseProps.image ? (
                        <img
                            src={QuestionnaireEmptyImageSrc}
                            alt=''
                        />
                    ) : (
                        <React.Fragment>
                            {QuestionnairePagesImages[elseProps.image]?.src ? (
                                <img
                                    src={QuestionnairePagesImages[elseProps.image].src}
                                    alt=''
                                />
                            ) : (
                                <i 
                                    className={elseProps.image} 
                                />
                            )}       
                        </React.Fragment>
                    )}
                </button>
                <span 
                    className='numbering'
                >
                    {pageIndex + 1}.
                </span> 
                {elseProps?.state?.editTitle ? (
                    <Input
                        placeholder={t('Page Title')}
                        className='w-auto'
                        type='text'
                        defaultValue={elseProps.title}
                        onKeyDown={(e) => {
                            if (e.keyCode === 13) {
                                saveTitle(e.target.value)
                            }
                        }}
                        onBlur={(e) => {
                            saveTitle(e.target.value)
                        }}
                    />
                ) : (
                    <h3
                        onClick={() => {
                            setElementStateField(
                                elseProps.id,
                                'editTitle',
                                true
                            )
                        }}
                    >
                        {elseProps.title}
                    </h3>
                )}
            </div>

            <div
                className={`smartintegrity__questionnaire__designer-page-questions ${elseProps.state?.collapsed ? 'show' : 'hide'}`}
            >
                <ReactDragListView
                    nodeSelector='.smartintegrity__questionnaire__designer-question-item.page-question'
                    handleSelector='.smartintegrity__questionnaire__designer-drag-btn.page-question'
                    onDragEnd={(fromIndex, toIndex) => {
                        onDragEnd(fromIndex, toIndex, elseProps.id)
                    }}
                    lineClassName='smartintegrity__questionnaire__designer-question-item-placeholder'
                >
                    {childElements.map((childElement, elementIndex) => {
                        if (childElement.elementType === 'question') {
                            return (
                                <Question
                                    key={elementIndex}
                                    {...childElement}
                                    elementIndex={`${pageIndex + 1}.${elementIndex + 1}.`}
                                    className='page-question'
                                />
                            )
                        } else if (childElement.elementType === 'panel') {
                            return (
                                <Panel
                                    key={elementIndex}
                                    {...childElement}
                                    elementIndex={`${pageIndex + 1}.${elementIndex + 1}.`}
                                />
                            )
                        }
                    })}
                </ReactDragListView>
            </div>

            {lng === 'en' && (
                <div className='smartintegrity__questionnaire__designer-add-new-question-btn-container'>
                    <button
                        onClick={() => {
                            addElement('question', {
                                title: t('Question Title'),
                                placeholder: newQuestionType.placeholder,
                                questionType: newQuestionType.value,
                                parentElementID: elseProps.id
                            })
                        }}
                        className='smartintegrity__questionnaire__designer-add-new-question-btn'
                    >
                        <i className='ri-add-fill text-success' />
                        {t('Add Question')}
                    </button>
                    <UncontrolledDropdown>
                        <DropdownToggle caret color='gray'>
                            {t(newQuestionType?.title)}
                        </DropdownToggle>
                        <DropdownMenu>
                            {QuestionTypes.map((questionType, typeIndex) => {
                                return (
                                    <DropdownItem
                                        onClick={() => {
                                            setNewQuestionType(questionType)
                                        }}
                                        key={typeIndex}
                                    >
                                        {t(questionType.title)}
                                    </DropdownItem>
                                )
                            })}
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </div>
            )}
        </div>
    )
})

export { Page }
